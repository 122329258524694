////// API ENDPOINTS ///////
export const API_AUTH_VALIDATE = 'auth/validate';
export const API_AUTH_SESSION = 'auth/session';
export const API_AUTH_LOGOUT = 'auth/logout';

export const API_SMILEY_MENU_ITEMS = 'get_smiley_menu_items';

export const API_UPSERT_CARE_RECIPIENT = 'care-recipient/upsert';
export const API_CR_PROFILE = 'care-recipient/get-cr-co-profile/'; // + CR_ID
export const API_CR_STATES = 'care-recipient/states';
export const API_FIND_CO_CR = 'priority-inbox/find-co-cr-vwo';
export const API_GET_SERVICES = 'service/all';

export const API_CANCEL_VISITS = 'visits/cancel';
export const API_CANCEL_REVIEW = 'visits/cancel/review';
export const API_SHORT_VISITS = 'visits/short/'; // + CR_ID/START DATE/END DATE
export const API_LONG_VISITS = 'visits/long/'; // + CR_ID/START DATE/END DATE
export const API_LONG_VISITS_BY_ID = 'visits/details'; // visit_ids[]
export const API_PAST_SUMMARY = 'visits/past-summary/'; // + CR_ID
export const API_FUTURE_SUMMARY = 'visits/future-summary/'; // + CR_ID
export const API_PREVIOUS_VISIT = 'visits/previous-visit/'; // + CR_ID
export const API_CREATE_VISITS = 'visits/create';

// Normal visit drafts created in VAL
export const API_DRAFTS_BY_CR = 'visit-drafts/cr/'; // + CR_ID
export const API_DRAFTS_UPSERT = 'visit-drafts/upsert/'; // + CR_ID
export const API_DRAFTS_DELETE = 'visit-drafts/delete/many';

// Mobile visit drafts from CO App
export const API_MOB_DRAFTS_BY_CR = 'mobile-visit-drafts/cr/'; // + CR_ID
export const API_MOB_DRAFTS_UPSERT = 'mobile-visit-drafts/upsert/'; // + CR_ID
export const API_MOB_DRAFTS_DELETE = 'mobile-visit-drafts/delete/many';

// Enterprise visit drafts from Enterprise web/b2b App
export const API_ENT_DRAFTS_BY_CR = 'enterprise-visit-drafts/cr/'; // + CR_ID
export const API_ENT_DRAFTS_UPSERT = 'enterprise-visit-drafts/upsert/'; // + CR_ID
export const API_ENT_DRAFTS_DELETE = 'enterprise-visit-drafts/delete/many';

// Update visit drafts (saved while updating the visits)
export const API_UPDATE_DRAFTS_SAVE = 'visits/save-update-drafts';
export const API_UPDATE_DRAFTS_GET = 'visits/get-update-drafts/'; // + draft-id
export const API_UPDATE_DRAFTS_DELETE = 'visits/delete-update-drafts';

// Pending payment drafts
export const API_PENDING_PAYMENT_DRAFTS_GET = 'pending-payment-drafts/cr/'; // + crid + '/' + draft-id

export const API_PI = 'priority-inbox';
export const API_PI_VISIT_COUNTS = 'priority-inbox/visit-counts';
export const API_PI_CLAIM = 'priority-inbox/claim';
export const API_PI_RELEASE = 'priority-inbox/release';
export const API_PI_CHANGE_PRIORITY = 'priority-inbox/change-priority';

//WhatsApp Blast
export const GET_API_WHATSAPP_TOTAL_RECIPIENT = 'priority-inbox/total-cps-recipient';
export const POST_API_WHATSAPP_BLAST_MESSAGES = 'priority-inbox/whatsapp-blast';

export const API_GET_REASONS = 'visits/update/get-reasons';
export const API_GET_CANCEL_REASONS = 'visits/update/get-cancel-reasons';
export const API_SIMILAR_VISITS = 'visits/similar-visit/'; // + visit_id
export const API_UPCOMING_VISITS = 'visits/upcoming-visits/'; // + CR_ID (all upcoming visits of cr)

//TODO: remove both
export const API_PRICING_GENERATE = 'pricing/generate';
export const API_PRICING_GET_BREAKDOWN = 'pricing/get-co-breakdown-fees';

// new pricing apis
export const API_VAL_PRICING_GENERATE = 'val/pricing/generate';
export const API_VAL_GET_BREAKDOWN = 'val/pricing/get_breakdown';
export const API_VAL_CONFIRM_BOOKING = 'val/pricing/confirm_booking';

export const API_UPDATE_VISITS = 'visits/update';

export const API_SELF_SERVE_CONFIG = 'careowner/self_serve_option';
export const API_STC_JOBS = 'stc/filter';
export const API_STC_ORGANISATION = 'vwo/facilities';
export const API_STC_FORM = 'stc/form';
export const API_STC_CREATE = 'stc';

export const API_CARE_OWNERS = 'careowner/all';
export const API_UPSERT_CARE_OWNER = 'careowner/upsert';

// Preferred CPs
export const API_SEARCH_PREFERRED_CPS = 'care-recipient';

// CP GST Status
export const API_GET_ALL_CPS = 'carepro/cp-gst-status/all_cps';
export const API_GET_ACTIVITY_LOG = 'carepro/cp-gst-status/activity-log';
export const API_POST_CP_GST_STATUS = 'carepro/cp-gst-status';

// CP Waivers
export const API_POST_CP_WAIVERS = 'carepro/cp-waivers';

// Offline payments
export const API_OFFLINE_PAYMENTS_CP_SEARCH = 'smiley-dev/offline-payments?query=';
export const API_OFFLINE_PAYMENTS_TABLE_LIST = 'smiley-dev/offline-payments';
export const API_OFFLINE_PAYMENTS_REMOVE_CO = 'smiley-dev/offline-payments/remove';
export const API_OFFLINE_PAYMENTS_ADD_CO = 'smiley-dev/offline-payments/add';

// B2B Export
export const API_GET_ORGANISATIONS_LIST = 'b2b/organisations_list';
export const API_POST_B2B_GENERATE_EXPORT = 'b2b/organisations_generate_export';
export const API_GET_EXPORT_HISTORY = 'b2b/export_history';

// Account details
export const API_GET_ACCOUNT_DETAILS = 'user/account-details';
export const API_POST_NEW_PASSWORD = 'user/new-password';
export const API_GET_PASSWORD_EXPIRY = 'user/password-expiry';

// Visits
export const API_POST_VISITS_FILTER = 'visits/filter';
export const API_GET_ORGANISATION_OPTIONS_LIST = 'visits/organisation_options';
export const API_GET_COCRCPCF_SEARCH = 'visits/user_search?query=';
export const API_GET_VISIT_INCENTIVE_CONFIG = 'visits/incentive/config';
export const API_POST_ADD_INCENTIVE = 'visits/incentive/add';
export const API_GET_CP_FILTERS = 'carepro/filter/config';
export const API_POST_CP_FILTERS = 'carepro/filter';
export const API_POST_INVITE_CPS = 'visits/incentive/invite';
export const API_POST_DELETE_INCENTIVE = 'visits/incentive/delete';
export const API_POST_UPDATE_INCENTIVE = 'visits/incentive/update';
export const API_POST_EXPORT_VISITS = 'visits/export';
export const API_POST_EXPORT_VISIT_STATUS = 'visits/export_status';

// CP Dashboard
export const API_POST_SEND_NOTIFICATION = 'carepro-dashboard/send-notification';
export const API_GET_EMAIL_VARIABLES = 'carepro-dashboard/send-notification/email-variables';
export const API_POST_CP_DASHBOARD = 'carepro-dashboard/table';
export const API_POST_SEARCH_CP = 'carepro-dashboard/search-cp';
export const API_GET_STAGE_STATUS_OPTIONS = 'carepro-dashboard/stage-status-filter-options';
export const API_GET_FILTER_OPTIONS = 'carepro-dashboard/filter-options';

// NOK PC App Activation Dashboard
export const API_GET_ALL_NOKS = 'nok/all_noks';
export const API_POST_NOK_ACTIVATION_DASHBOARD = 'nok/nok-activation-dashboard';
export const API_POST_SEND_INVITES = 'nok/send-invitation-nok';

// Recruitment Workflow List
export const API_GET_ALL_WORKFLOWS = 'recruitment-workflow/all-workflows';
export const API_POST_RECRUITMENT_LIST = 'recruitment-workflow/list';

// Recruitment Workflow Settings
export const API_GET_RECRUITMENT_SETTINGS = 'recruitment-workflow/workflow-settings';
export const API_POST_RECRUITMENT_SETTINGS = 'recruitment-workflow/stage-settings';
export const API_GET_RECRUITMENT_DATA_KEYS = 'recruitment-workflow/data-keys';
export const API_GET_RECRUITMENT_TEMPLATES = 'recruitment-workflow/message-templates';
export const API_GET_RECRUITMENT_REASONS = 'recruitment-workflow/reasons';

export const API_GET_RECRUITMENT_EMAIL_TEMPLATE_LIST = 'recruitment-workflow/email-template/list';
export const API_GET_RECRUITMENT_EMAIL_TEMPLATE_DELETE = 'recruitment-workflow/email-template/delete';
export const API_GET_RECRUITMENT_EMAIL_TEMPLATE_CREATE = 'recruitment-workflow/email-template/create';

/* external pages */
export const API_GET_CP_FORM = 'carepro/apply';
export const API_POST_CP_FORM = 'carepro/apply';
export const API_PIQ_FORM_CONFIG = 'carepro/piq-form';
export const API_POST_PIQ_FORM = 'carepro/piq-form';

export const API_GET_PAYMENT_DATA = (paymentId: string, paymentMethod = 'card') =>
  `careowner/payment/${paymentId}?payment_method=${paymentMethod}`;
export const API_MAKE_PAYMENT = (id: string) => `careowner/payment/${id}`;

export const API_PAYMENTS_DASHBOARD = 'payments-dashboard';
export const API_DELAY_PAYMENT = 'payments-dashboard/delay-payment';
export const API_REPORT_PAYMENT = 'payments-dashboard/report-payment';
export const API_REPORT_EXPORT = 'payments-dashboard/export';
export const API_GET_BOOKING_SUMMARY = 'payments-dashboard/booking-summary/'; // + 'visit_id'
export const API_SEARCH_CO_CR = 'payments-dashboard/search_co_cr?query='; // + 'query string'
export const API_WALLET_PACKAGES = 'careowner/wallet_packages/';
export const API_UPLOAD = 'upload';

export const API_ADD_CARD = 'val/add_card';

export const API_ADD_WEBLINK_CARD = 'careowner/payment/add_weblink_card';
export const API_WEBLINK_CONFIRM = 'careowner/payment/confirm';
////// API ENDPOINTS ///////

export const UNDEF = undefined;

////// COLORS ///////
export const GREEN_COLOR = '#32bdc7';
export const ORANGE_COLOR = '#ffbc49';
export const PAST_VISIT_COLOR = '#d0d0d0';

export const SCHEDULED_COLOR = '#fff';
export const CONFIRMED_COLOR = '#6ECB5B';
export const COMPLETED_COLOR = '#6ECB5B';
export const CANCELLED_COLOR = '#ECECEC';
export const SCHEDULED_BORDER_COLOR = '#F0A758';
export const OVERLAP_BORDER_COLOR = 'rgba(255, 255, 255, 0.62)';
////// END COLORS ///////

/// Post message types
export const MSG_OPEN_VISIT_DIALOG = 'open-visit-dialog';
export const MSG_CLOSE_VISIT_DIALOG_FROM_VISIT_CLICK = 'close-visit-dialog-from-visit-click';
export const MSG_CLOSE_VISIT_DIALOG_FROM_END_DRAG = 'close-visit-dialog-from-end-drag';
export const MSG_CLOSE_VISIT_DIALOG_FROM_OPEN_VISIT_DIALOG = 'close-visit-dialog-from-open-visit-dialog';

export const MSG_CLOSE_VISIT_DIALOG_FROM_LISTVIEW_ADDREQUIREMENTS = 'close-visit-dialog-from-listview-addrequirements';
export const MSG_CLOSE_VISIT_DIALOG_FROM_LISTVIEW_MEDESCORT = 'close-visit-dialog-from-listview-medescort';
export const MSG_CLOSE_VISIT_DIALOG_FROM_LISTVIEW_PATIENTDECL = 'close-visit-dialog-from-listview-patientdecl';
export const MSG_CLOSE_VISIT_DIALOG_FROM_LISTVIEW_PREFERREDCAREPRO =
  'close-visit-dialog-from-listview-preferredcarepro';

export const MSG_WEEK_CHANGE = 'week-change';
export const MSG_DATE_CHANGE = 'date-change';
export const MSG_EXPAND_COLLAPSE = 'expand-collapse';
export const MSG_SHOW_UPDATE_REVIEW = 'show-up-review';
export const MSG_SHOW_CALENDAR_VIEW = 'show-cal-view';
export const MSG_REFRESH_CR_INFO = 'msg-refresh-cr';
export const MSG_REFRESH_CR_DETAILS = 'msg-refresh-cr-details';
export const SHOW_LOADING = 'show-loading';
export const MSG_SET_LOADING_STYLE = 'style-loading';
export const MSG_SET_LOADING_CONT_STYLE = 'style-loading-cont';
export const MSG_SHOW_SNACKBAR = 'show-snackbar';
export const MSG_FETCH_VISITS = 'fetch-visits';
export const MSG_PRICING_RESET = 'pricing-reset';
export const MSG_ON_PAGE_FOCUS = 'msg-on-page-focus';
export const MSG_AFTER_PRICING_GENERATED = 'after-pricing-generated';
export const MSG_OPEN_ADD_EMERGENCY_CONTACT_DIALOG = 'open-add-emergency-contact-dialog';
export const MSG_OPEN_EDIT_EMERGENCY_CONTACT_DIALOG = 'open-edit-emergency-contact-dialog';
export const MSG_EMERGENCY_CONTACT_DIALOG_CALL_API = 'emergency-contact-dialog-call-api';
export const MSG_EMERGENCY_CONTACT_DIALOG_API_SUCCESS = 'emergency-contact-dialog-api-success';
export const MSG_EMERGENCY_CONTACT_DIALOG_API_FAILED = 'emergency-contact-dialog-api-failed';
/// End Post message types

// REDUX Actions
export const GET_VISIT_DRAFTS_SUCCESS = 'GET_VISIT_DRAFTS_SUCCESS';
export const GET_MOB_VISIT_DRAFTS_SUCCESS = 'GET_MOB_VISIT_DRAFTS_SUCCESS';
export const GET_ENT_VISIT_DRAFTS_SUCCESS = 'GET_ENT_VISIT_DRAFTS_SUCCESS';
export const SAVE_VISIT_DRAFT_SUCCESS = 'SAVE_VISIT_DRAFT_SUCCESS';
export const SET_DRAFTS_TYPE = 'SET_DRAFTS_TYPE';
export const GET_CR_DETAILS_SUCCESS = 'GET_CR_DETAILS_SUCCESS';
export const GET_CR_VISITS_SUCCESS = 'GET_CR_VISITS_SUCCESS';
export const GET_CR_LONG_VISITS_SUCCESS = 'GET_CR_LONG_VISITS_SUCCESS';
export const GET_CR_PAST_SUMMARY_SUCCESS = 'GET_CR_PAST_SUMMARY_SUCCESS';
export const GET_CR_FUTURE_SUMMARY_SUCCESS = 'GET_CR_FUTURE_SUMMARY_SUCCESS';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const SET_ALL_VISIT_CHECKED = 'SET_ALL_VISIT_CHECKED';
export const SET_VISIT_CHECKED = 'SET_VISIT_CHECKED';
export const DELETE_VISIT_DRAFT = 'DELETE_VISIT_DRAFT';
export const API_AUTH_EXPIRED = 'API_AUTH_EXPIRED';
export const GENERATE_PRICING_DRAFT = 'GENERATE_PRICING_DRAFT';
export const APPLY_PRICING = 'APPLY_PRICING';
export const APPLY_PRICING_START = 'APPLY_PRICING_START';
export const RESET_PRICING = 'RESET_PRICING';
export const RESET_PRICING_ORIGINAL_STATE = 'RESET_PRICING_ORIGINAL_STATE';
export const CREATE_VISITS = 'CREATE_VISITS';
export const GET_PREVIOUS_VISIT = 'GET_PREVIOUS_VISIT';
export const SAVE_OR_UPDATE_DRAFT = 'SAVE_OR_UPDATE_DRAFT';
export const GET_STATES = 'GET_STATES';
export const UPDATE_LOCAL_VISIT_DRAFTS = 'UPDATE_LOCAL_VISIT_DRAFTS';
export const SAVE_OR_UPDATE_DRAFT_LOADING = 'SAVE_OR_UPDATE_DRAFT_LOADING';
export const SET_UPDATE_VISITS_DATA = 'SET_UPDATE_VISITS_DATA';
export const GENERATE_PRICING_DRAFT_LOADING = 'GENERATE_PRICING_DRAFT_LOADING';
export const GET_PRIORITY_INBOX_VISIT_COUNT = 'GET_PRIORITY_INBOX_VISIT_COUNT';
export const GET_CR_CO_VWO_LIST = 'GET_CR_CO_VWO_LIST';
export const GET_REASONS = 'GET_REASONS';
export const GET_CANCEL_REASONS = 'GET_CANCEL_REASONS';
export const GET_SIMILAR_VISITS = 'GET_SIMILAR_VISITS';
export const GET_UPCOMING_VISITS = 'GET_UPCOMING_VISITS';
export const GET_CARE_OWNER_REQUEST = 'GET_CARE_OWNER_REQUEST';
export const GET_CARE_OWNER_SUCCESS = 'GET_CARE_OWNER_SUCCESS';
export const GET_CARE_OWNER_FAILED = 'GET_CARE_OWNER_FAILED';
export const SET_CURRENT_SELECTED_RECIPIENT = 'SET_CURRENT_SELECTED_RECIPIENT';
export const GET_SELF_SERVE_OPTIONS_REQUEST = 'GET_SELF_SERVE_OPTIONS_REQUEST';
export const GET_SELF_SERVE_OPTIONS_SUCCESS = 'GET_SELF_SERVE_OPTIONS_SUCCESS';
export const GET_SELF_SERVE_OPTIONS_FAILED = 'GET_SELF_SERVE_OPTIONS_FAILED';
export const GET_PREFERRED_CARE_PROS_REQUEST = 'GET_PREFERRED_CARE_PROS_REQUEST';
export const GET_PREFERRED_CARE_PROS_SUCCESS = 'GET_PREFERRED_CARE_PROS_SUCCESS';
export const GET_PREFERRED_CARE_PROS_FAILED = 'GET_PREFERRED_CARE_PROS_FAILED';
export const CLEAR_PREFERRED_CARE_PROS_LIST = 'CLEAR_PREFERRED_CARE_PROS_LIST';
export const API_ERROR = 'API_ERROR';
// End REDUX Actions

//TODO: remove
export const GENERATE_FIRST_VISIT_PRICING_PACKAGE = 'GENERATE_FIRST_VISIT_PRICING_PACKAGE';

// SiderBar Menu
export const GET_MENU_LINKS_SUCCESS = 'GET_MENU_LINKS_SUCCESS';
export const SET_CURRENT_MENU_SELECTION = 'SET_CURRENT_MENU_SELECTION';
export const SET_MENU_COLLAPSED_STATE = 'SET_MENU_COLLAPSED_STATE';

//SMILEY links

export const CITY_STATES_API = 'care-recipient/city-states';
export const getSuburbStatePostcode = (id: string) => `care-recipient/suburb-state-postcode/${id}`;

export const SUBURB_STATE_POSTCODE_API = 'care-recipient/suburb-state-postcode/RqLvjBS6L';
export const VISITS_BY_CR = (id: string) => `${process.env.REACT_APP_SMILEY}/admin_recipient_details/${id}/visits`;
export const CR_VISIT_HISTORY = (id: string, isFacility = false) =>
  isFacility
    ? `${process.env.REACT_APP_SMILEY}/admin_vwo_organisations/care_facility/${id}`
    : `${process.env.REACT_APP_SMILEY}/admin_recipient_details/${id}`;

export const CR_LIST = `${process.env.REACT_APP_SMILEY}/care_owners`;
export const VISIT_MATCHING = (visit_id: string) =>
  `${process.env.REACT_APP_SMILEY}/start_matching?visit_id[]=${visit_id}`;
export const ACTION_CENTER = `${process.env.REACT_APP_SMILEY}/open_actions`;
export const SMILEY_VISIT_DETAILS = `${process.env.REACT_APP_SMILEY}/admin_individual_visits/`;
export const SMILEY_CP_DETAILS = `${process.env.REACT_APP_SMILEY}/admin_carepros/`;
export const SMILEY_CR_INCIDENTS = `${process.env.REACT_APP_SMILEY}/open_incidents?cr_id=`;
export const SMILEY_MATCHING_DETAILS = `${process.env.REACT_APP_SMILEY}/matching_details?matching_id=`;
export const SMILEY_CR_PROFILE = `${process.env.REACT_APP_SMILEY}/admin_recipient_details/`;

//DATE
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

//PAGINATION
export const PAGINATE_PAGE_COUNT = 10;

//Variables
export const SPEECH_THERAPY = 'Speech Therapy';

// Colors
export const cWhite = '#fff';
export const c4A4A4A = '#4A4A4A';
export const cA4A4A4 = '#A4A4A4';
export const cECECEC = '#ECECEC';
export const c32BDC7 = '#32BDC7';
export const cC9C9C9 = '#C9C9C9';
export const c7C7C7C = '#7C7C7C';
